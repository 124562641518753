<template>
  <ws-dialog
      v-model="display"
      :title="$t('avalon.language.title')"
      width="400"
      @save="editLanguagesSettings"
  >
    <v-sheet :style="`border : 1px solid ${wsDARKLIGHT}`" class=" wsRoundedLight mt-3">
      <div v-for="(lang,i) in $store.state.business.selectedBusiness.langs || []" :key="i">
        <div @click="selectLang(lang)" class="d-flex pointer align-center py-2 px-3">
          <ws-check-box
              @prevent="selectLang(lang)"
              :value="entityData.langs.includes(lang)"
              class="mr-n1"
              :color="wsACCENT"
              prevent-select
          />
          <h5 :style="`color : ${wsDARKER}`">{{ lang.toUpperCase() }}</h5>
        </div>


        <v-divider v-if="i + 1 < $store.state.business.selectedBusiness.langs.length" :style="`border-color : ${wsDARKLIGHT}`" />
      </div>
    </v-sheet>

    <ws-select
        class="mt-6 mb-n5"
        :items="languagesSelect"
        v-model="entityData.lang_primary"
        :label="$t('avalon.language.primary')"
    />

    <ws-button
        @click="displayLangDialog = true"
        class="mt-5"
        label="avalon.language.add_language"
        block />

    <add-edit-language-dialog
        @save="addNewLanguage"
        v-model="displayLangDialog"
        v-if="displayLangDialog"
        display-info
        new-entity
    />

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";
import addEditLanguageDialog from "@/components/pages/businessDashboard/businessSettings/UI/addEditLanguageDialog";

export default {
  name: "AvalonLanguageSelector",
  components : {
    addEditLanguageDialog
  },
  props : {
    value : {
      type : Boolean
    },
  },
  data() {
    return {
      display : false,
      displayLangDialog : false,
      entityData : {
        langs : [] ,
        primary_lang : null
      }
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
      if ( this.display ) {
        this.initEntityData()
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  computed : {
    languagesSelect() {
      let items = []

      this.AVALON_LANGS.forEach(lang => {
        items.push({ text : lang.toUpperCase() , value : lang })
      })

      return items
    }
  },
  methods : {
    ...mapActions('avalon' , [
      'SAVE_SITE_SETTINGS'
    ]),

    addNewLanguage(data) {
      this.entityData.langs.push(data.lang)
    },
    selectLang(lang) {
      if ( this.entityData.langs.includes(lang)) {
        if (this.entityData.langs.length < 2 ) {
          this.notify(this.$t('avalon.language.remove_lang_error'),'warning')
          return
        }
        if (lang === this.entityData.lang_primary ) {
          this.notify(this.$t('avalon.language.remove_lang_primary_error'),'warning')
          return
        }
        this.entityData.langs = this.entityData.langs.filter(el => el !== lang)
      } else {
        this.entityData.langs.push(lang)
      }
      this.entityData = this.COPY(this.entityData)
    },
    async editLanguagesSettings() {

      let result = await this.SAVE_SITE_SETTINGS(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }

      this.$store.state.avalon.langs = this.entityData.langs
      this.$store.state.avalon.langs = this.COPY(this.$store.state.avalon.langs)
      this.$store.state.avalon.langPrimary = this.entityData.lang_primary

      if (!this.AVALON_LANGS.includes(this.$store.state.avalon.selectedLang)) {
        this.$store.state.avalon.selectedLang = this.AVALON_LANG_PRIMARY
      }
      this.display = false

    },

    initEntityData() {
      this.entityData = {
        langs : this.COPY(this.AVALON_LANGS),
        lang_primary:  this.AVALON_LANG_PRIMARY
      }
    }
  },
  beforeMount() {
    this.initEntityData()
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>