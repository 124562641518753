<template>
  <v-sheet
      :color="!dark ? wsBACKGROUND : null"
      :dark="dark"
      style="position: relative; border-top-left-radius : 4px;border-top-right-radius : 4px"
      class="d-flex flex-row pt-1 pb-1"
      width="225"
      height="41"
  >
    <!-- Bottom White Placeholder-->
    <v-sheet
        :color="!dark ? 'white' : wsDARKER"
        style="position: absolute; bottom: 0"
        width="100%"
        height="15"

    />

    <!-- Left Corner -->
    <v-sheet
        :color="!dark ? wsBACKGROUND : null"
        style="border-bottom-right-radius: 12px; z-index: 1"
        class="fill-height"
        width="40"

    />

    <!-- MAIN CONTENT -->
    <v-sheet
        :color="!dark ? 'white' : wsDARKER"
        style="border-top-left-radius: 12px; border-top-right-radius: 12px; z-index: 1"
        class="fill-height py-2  px-2 d-flex align-center justify-space-between"
        min-width="155"
        width="155"
        max-width="155"
    >
      <img :src="!dark ? ($store.state.avalon.favicon || '/favicon.png') : ($store.state.avalon.faviconDark || $store.state.avalon.favicon || '/favicon-dark.png')" width="16px" height="16px">
      <div style="width: 100%; position: relative; overflow-x: hidden" class="px-1">
        <h5 class="font-weight-regular text-no-wrap">
          {{ $store.state.business.selectedBusiness.name || $t('Name') }}
        </h5>
        <div style="position: absolute; top : 0; left: 0;right: 0; bottom: 0"
             :style="`background: linear-gradient(to left, ${!dark ? 'white' : wsDARKER} , #00000000 ,#00000000 , #00000000);`"
        />
      </div>

      <v-icon :color="!dark ? wsACCENT : null" small>mdi-close</v-icon>


    </v-sheet>

    <!-- Right Corner -->
    <v-sheet
        :color="!dark ? wsBACKGROUND : null"
        style="border-bottom-left-radius: 12px; z-index: 1"
        class="fill-height" width="40"
    />

  </v-sheet>
</template>

<script>
export default {
  name: "faviconViewer",
  props : {
    dark : {
      type : Boolean,
      default : false
    }
  }

}
</script>

<style scoped>
</style>