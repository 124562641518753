<template>

  <v-fade-transition class="fill-height" :style="`background-color: white`" mode="out-in">

    <div v-if="loggedIn && !loading" class="fill-height" >

      <v-sheet  id="business_dash_content"  :color="wsBACKGROUND" class=" fill-height">

        <v-row v-if="!noWebsite" no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <v-col :style="sideBarStyle" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <avalon-side-bar />
          </v-col>

          <v-col v-if="$store.state.avalon.menuNavigation !== 'settings_general'"  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">



            <avalon-blank-homepage v-if="isBlankHomepage" />
            <avalon-page-editor v-else-if="!course && (page || newsAlias)" :page="page" :news-alias="newsAlias" />
            <dash-page-new v-else-if="!course && !page">
              <div class="d-flex justify-center align-center fill-height">
                <div>
                  <div class="d-flex justify-center">
                    <v-icon :color="wsDARKLIGHT" size="80">mdi-file</v-icon>
                  </div>
                  <h3 class="text-center">
                    {{ $t('avalon.no_page_selected') }}
                  </h3>
                  <h4 class="text-center font-weight-regular">
                    {{ $t('avalon.no_page_selected_text') }}
                  </h4>
                </div>
              </div>
            </dash-page-new>
            <avalon-course-page v-else-if="course" :course="course" />

          </v-col>

          <!-- Avalon Settings General-->
          <v-col v-else-if="$store.state.avalon.menuNavigation === 'settings_general'"  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
            <avalon-settings />
          </v-col>


        </v-row>
        <div v-else
            class="
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6" >

          <v-sheet color="transparent"  width="600">

            <div class="d-flex justify-center">
              <img width="200" height="auto"  src="@/assets/img/avalon/wizard_from_blocks.png" >
            </div>

            <h3 style="font-size: 22px; font-weight: 700" class="text-center  mt-6">
              {{ $t('avalon.website.blank.title') }}
            </h3>
            <h4  class="text-center font-weight-regular mb-6 mt-3">
              {{ $t('avalon.website.blank.text') }}
            </h4>

            <div class="d-flex justify-center">
              <ws-button
                  :to="businessDashLink()"
                  label="Return"
                  outlined
                  class="mr-2"
              />
              <ws-button
                  @click="displayNewSiteDialog = true"
                  label="CreateSite"
              />
            </div>



          </v-sheet>

        </div>

      </v-sheet>




      <avalon-create-site-dialog
          v-if="noWebsite"
          v-model="displayNewSiteDialog"
      />

      <avalon-new-language-dialog
          v-if="$store.state.avalon.displayLanguageDialog"
      />
      <avalon-new-page-dialog
          v-if="$store.state.avalonPage.displayPageDialog"
      />
      <avalon-delete-page-dialog
          v-if="$store.state.avalonPage.displayPageDeleteDialog"
      />
      <avalon-new-news-dialog
          v-if="$store.state.avalonPage.displayNewsDialog"
      />

      <!-- LOGOUT DIALOG-->
      <ws-dialog v-if="$store.state.dashboard.showLogoutConfirm"
                 v-model="$store.state.dashboard.showLogoutConfirm"
                 :title="$t('Logout')"
                 :save-text="$t('Yes')"
                 @delete="logout"
                 display-confirm-delete
                 close-delete
                 delete-text="Exit"
                 portal
      >
        <template #confirm-delete>
          <h3 class="my-12 text-center ">{{ $t('AreYouSureYouWantToQuit') }}</h3>
        </template>
      </ws-dialog>


    </div>

    <!-- // Authorization Content -->
    <v-sheet v-else-if="!loggedIn && !loading" :color="wsBACKGROUND" class="d-flex align-center justify-center" >
      <v-sheet class=" pa-6 px-12" :class=" displayHide(['sm']) ? 'wsRoundedHalf' : '' "
               :width="displayHide(['sm']) ? 500 : '100vh'"
               :height="displayHide(['sm']) ? 700 : '100vh'">

        <h3 v-if="displayHide(['sm'])"  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h3>
        <h4 v-else  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h4>
        <authWindow light full-size no-toggle class="mt-16" />
      </v-sheet>
    </v-sheet>
    <v-sheet dark v-if="loading" :color="wsBACKGROUND" class="d-flex align-center justify-center fill-height">
      <div>
        <div class="d-flex justify-center">
          <v-progress-circular class="text-center mx-auto" size="48" indeterminate />
        </div>
        <h3 class="text-center font-weight-light">{{ $t('Loading') }}</h3>
      </div>
    </v-sheet>



  </v-fade-transition>

</template>

<script>

import {mapState, mapActions, mapMutations} from "vuex";

import defaultConfig from '@/assets/json/avalon/defaultConfig.json'
import blocksConfig from '@/assets/json/avalon/blocksConfig.json'
import elementsConfig from '@/assets/json/avalon/elements_config_variables.json'

import colors_variables from '@/assets/json/avalon/colors_variables.json'
import fonts_variables from '@/assets/json/avalon/fonts_variables.json'
import fonts_list from '@/assets/json/fonts_list.json'

import avalonSettings from "@/components/AvalonEditor/settings/AvalonSettings";

import Quill from 'quill'


export default {
  name: 'PageBusinessDashboard',
  props: ['alias' , 'page' , 'course' , 'blank_homepage' , 'newsAlias' , 'webinarUuid'],
  components: {
    avalonSettings ,
    avalonPageEditor    : () => import("@/components/AvalonEditor/Editor/avalonPageEditor"),
    avalonBlankHomepage : () => import("@/components/AvalonEditor/Editor/avalonBlankHomepage"),
    avalonCoursePage    : () => import("@/components/AvalonEditor/Editor/avalonCoursePage"),
    // Dialogs
    avalonNewPageDialog     : () => import("@/components/AvalonEditor/Dialogs/Page/avalonNewPageDialog"),
    avalonNewNewsDialog     : () => import("@/components/AvalonEditor/Dialogs/Page/avalonNewNewsDialog"),
    avalonDeletePageDialog  : () => import("@/components/AvalonEditor/Dialogs/Page/avalonDeletePageDialog"),
    avalonCreateSiteDialog  : () => import("@/components/AvalonEditor/Dialogs/newSiteDialog/AvalonCreateSiteDialog"),
    // UI
    avalonSideBar : () => import("@/components/AvalonEditor/UI/sidebar/avalonSideBar"),
    authWindow    : () => import("@/components/auth/authWindow"),
  },
  data() {
    return {
      menuActive: false,
      loading : true,
      noWebsite : false,
      displayNewSiteDialog : false
    }
  },
  computed: {
    ...mapState('avalon', ['sideMenu' , 'selectedNews']),
    ...mapState('auth', ['loggedIn']),

    isBlankHomepage() {
      return this.$route.path.includes('blank/homepage')
    },

    sideBarStyle() {
      let width = this.sideMenu ? 280 : 0;
      return `min-width: ${width}px; max-width: ${width}px; height: 100% !important; transition : all 0.3s ease`
    },

    mainClass() {
      if ( this.displayShow(['sm'])) { return 'mainSmall' }
      return this.sideMenu ? 'mainWithMenu' : 'main';
    }

  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.initBusiness()
      }
    },
  },
  methods : {
    ...mapActions('avalon', ['GET_BUSINESS_AVALON']),
    ...mapMutations('auth',['LOGOUT']),
    ...mapActions('avalon', [
        'GET_MENU_NAVIGATION' ]
    ),

    logout() {
      this.$store.state.dashboard.showLogoutConfirm = false
      this.LOGOUT(this.$router)
      this.$router.push(this.businessPublicLink(''))
    },

    async initBusiness() {
      this.loading = true

      if ( !this.loggedIn) {

        let business = await this.GET_BUSINESS_AVALON({ business : this.$store.state.domain ? location.host : this.alias , public : true})
        if ( !business ) {
          this.loading = false
          this.ERROR()
        }
        this.$store.state.business.selectedBusiness = business
        this.loading = false
        return
      }

      let result = await this.GET_BUSINESS_AVALON({ business : this.$store.state.domain ? location.host : this.alias})
      if ( !result ) {
        return
      }
      if ( result.noWebsite ) {
        this.noWebsite = true
        // this.displayNewSiteDialog = true
        this.loading = false
        return
      }

      this.CHECK_CONFIG_PARAM("fonts" , "families")
      this.LOAD_FONTS()
      this.loading = false
      await this.GET_MENU_NAVIGATION()
      if ( !this.page && !this.course && !this.newsAlias ) {
        this.$router.push(this.businessPublicLink(this.CURRENT_LANG_HOMEPAGE ? `page/${this.CURRENT_LANG_HOMEPAGE.value}/editor` : 'blank/homepage/editor' ))
      }
    },
    initQuill() {
      let Inline = Quill.import('blots/inline');
      class CustomSpanBlot extends Inline {

        static create(value) {
          let node = super.create();
          node.setAttribute('class', value);
          node.setAttribute('id', 'wsColor');
          return node;
        }

        static formats(domNode) {
          return domNode.getAttribute('class') || true;
        }

        format(name, value) {
          if (name === 'class') {
            this.domNode.setAttribute('class', value);
          } else {
            super.format(name, value);
          }
        }

      }
      CustomSpanBlot.blotName = 'wsColor';
      CustomSpanBlot.tagName = 'span';
      Quill.register(CustomSpanBlot, true);

    },
    getMouseCoordinates(event) {
      this.$store.state.avalon.mouseX = event.clientX;
    },
    getMouseClickCoordinates(event) {
      this.$store.state.avalon.mouseClickX = event.clientX;
    }
  },
  beforeMount() {
    this.$store.state.avalon.editorMode = true
    this.$store.state.avalon.editorOn = true
    this.$store.state.avalon.defaultConfig = defaultConfig
    this.$store.state.avalon.blocksConfig = blocksConfig
    this.$store.state.avalon.elementsConfig = elementsConfig
    this.$store.state.avalon.colorsVariables = colors_variables
    this.$store.state.avalon.fontsVariables = fonts_variables

  },
  mounted() {
    this.$store.state.avalon.sideMenu = true
    this.$store.state.avalon.menuOnlyStylesAndFonts = false
    document.body.addEventListener("mousemove", this.getMouseCoordinates);
    document.body.addEventListener("mousedown", this.getMouseClickCoordinates);

    this.$store.state.avalon.fonts = fonts_list
    this.initQuill()

    this.initBusiness()
  },
  beforeDestroy() {
    this.$store.state.avalon.sideMenu = false
    this.$store.state.avalon.menuOnlyStylesAndFonts = false
    document.body.removeEventListener("mousemove", this.getMouseCoordinates);
    document.body.removeEventListener("mousedown", this.getMouseClickCoordinates);
    this.$store.state.avalon.editorOn = false
    this.$store.state.avalon.editorMode = false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrapper {
  height:100% ;
}
.mainSmall {
  transition: margin-left 0.3s ease-out;
}
.main {
  margin-left: 60px !important;
  transition: margin-left 0.3s ease-out;
}
.transition {
  transition: margin-left 0.3s ease-out;
}
.mainWithMenu {
  margin-left: 250px !important;
}


.content {
  overflow-y: unset;
  background-color: white;
}


</style>
