<template>
  <dash-page-new
      :title="$t('General')"
      :subtitle="$t('Settings')"
      :root="$t('WebEditor')"
      icon="mdi-cog"
      v-model="$store.state.settings.pageData"
  >
    <ws-accordion
        class="mt-5"
        :items="navigationItems"
    >
      <template #action.isPublished >

        <ft-select v-model="$store.state.avalon.siteIsPublished" @input="editSitePublishStatus" :items="CHOICE_PUBLISHED" class="pointer">
          <v-chip
              dark
              :color="wsACCENT"
              :style="!$store.state.avalon.siteIsPublished ? 'opacity : 0.5' : ''"
              class="px-1 justify-end font-weight-medium pointer mr-5"
          >
            <v-icon  class="mr-1" >
              {{  $store.state.avalon.siteIsPublished ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
            </v-icon>
            <h5 class="mx-2">{{ $store.state.avalon.siteIsPublished ? $t('Published') : $t('NotPublished')   }}</h5>
            <v-icon>mdi-menu-down</v-icon>
          </v-chip>
        </ft-select>

      </template>
      <template #action.gAnalytics>
        <ws-business-service-key-select
            v-model="serviceKeys.gTag"
            @input="$store.state.business.selectedBusiness.gTag = $event"
            service-key="gTag"
            :title="$t('LinkGoogleAnalytics')"
            label="Google Analytics Tag"
            placeholder="Google Tag"
            class="mr-5"
        />
      </template>
      <template #action.facebook_code>
        <ws-business-service-key-select
            v-model="serviceKeys.fPixelId"
            @input="$store.state.business.selectedBusiness.fPixelId = $event"
            service-key="fPixelId"
            :title="$t('LinkFacebookPixel')"
            label="Facebook Pixel"
            placeholder="Facebook Pixel"
            class="mr-5"
        >
          <template #description>
            <h4 class="font-weight-regular wsACCENT mt-5">
              {{ $t('PixelEventDescription') }}
            </h4>
          </template>
        </ws-business-service-key-select>
      </template>

      <template #action.language>

        <v-chip
            @click="openLangsDialog"
            dark
            :color="wsACCENT"
            :style="!$store.state.avalon.siteIsPublished ? 'opacity : 0.5' : ''"
            class="px-1 justify-end font-weight-medium pointer mr-5"
        >
          <v-icon  class="mr-1">mdi-check-circle-outline</v-icon>

          <h5 class="mx-2">
            <span v-for="(lang, i) in AVALON_LANGS" :key="lang">
              {{ lang.toUpperCase() + ( (AVALON_LANGS.length - 1) > i ? ` ,` : '') }}
            </span>
          </h5>

          <v-icon>mdi-menu-down</v-icon>

        </v-chip>

      </template>
      <template #action.favicon="{forcedExpand, item}">
        <v-chip
            @click="forcedExpand"
            dark
            :color="wsACCENT"
            :style="!$store.state.avalon.siteIsPublished ? 'opacity : 0.5' : ''"
            class="px-1 justify-end font-weight-medium pointer mr-5"
        >
          <h5 class="mx-6">
            {{ $t(!item.expand ? 'Edit' : 'Close')}}
          </h5>



        </v-chip>

      </template>

      <template #item.favicon>
        <div class="pa-6">

          <v-sheet style="border-radius: 8px" :color="wsLIGHTCARD" class="pa-4 d-flex align-center mb-6 ">
            <v-icon :color="wsACCENT" class="mr-3">mdi-information-outline</v-icon>
            <h5 class="font-weight-regular" style="line-height: 1.8" :style="`color : ${wsDARKER}`">{{ $t('avalon.settings.favicon.information') }}</h5>
          </v-sheet>


          <v-sheet style="border-radius: 8px" :color="wsLIGHTCARD" class="pa-6 d-flex align-center justify-space-between mb-6 ">

            <div>
              <h5 :style="`color : ${wsDARKER}`">
                {{ $t('avalon.settings.favicon.light_theme') }}
                <span class="font-weight-regular">({{ $t('avalon.settings.favicon.size_description') }})</span>
              </h5>

              <ws-file-uploader
                  v-if="!$store.state.avalon.favicon"
                  @success="uploadFavicon($event)"
                  :formats="['png','ico','jpeg','jpg']"
                  max-size="100"
                  public is-public
                  activator
              >
                <template #activator="{select, loading}">
                  <ws-button
                      @click="select"
                      :loading="loading"
                      label="Upload"
                      class="mt-3"
                  />
                </template>

              </ws-file-uploader>

              <div v-else class="d-flex mt-3 ">
                <v-sheet  :color="wsBACKGROUND" class="wsRoundedLight d-flex align-center justify-space-between pl-4 ">
                  <h5 :style="`color : ${wsACCENT}`" >{{ $store.state.avalon.favicon.split('/')[$store.state.avalon.favicon.split('/').length - 1] }}</h5>
                  <v-btn
                      @click="uploadFavicon(null)"
                      height="40" min-width="40"  width="40" text class="ml-1" >
                    <v-icon :color="wsACCENT">mdi-delete-outline</v-icon>
                  </v-btn>
                </v-sheet>
              </div>

            </div>

            <favicon-viewer />

          </v-sheet>

          <v-sheet style="border-radius: 8px" :color="wsLIGHTCARD" class="pa-6 d-flex align-center justify-space-between mb-6 ">

            <div>
              <h5 :style="`color : ${wsDARKER}`">
                {{ $t('avalon.settings.favicon.dark_theme') }}
                <span class="font-weight-regular">({{ $t('avalon.settings.favicon.size_description') }})</span>
              </h5>
              <ws-file-uploader
                  v-if="!$store.state.avalon.faviconDark"
                  @success="uploadFavicon($event , true)"
                  public is-public
                  :formats="['png','ico','jpeg','jpg']"
                  max-size="100"
                  activator
              >
                <template #activator="{select,loading}">
                  <ws-button @click="select" class="mt-3" label="Upload" :loading="loading" />
                </template>

              </ws-file-uploader>

              <div v-else class="d-flex mt-3 ">
                <v-sheet  :color="wsBACKGROUND" class="wsRoundedLight d-flex align-center justify-space-between pl-4 ">
                  <h5 :style="`color : ${wsACCENT}`" >{{ $store.state.avalon.faviconDark.split('/')[$store.state.avalon.faviconDark.split('/').length - 1] }}</h5>
                  <ws-tooltip :text="$t('avalon.settings.favicon.delete')">
                    <v-btn
                        @click="uploadFavicon(null , true)"
                        height="40" min-width="40"  width="40" text class="ml-1" >
                      <v-icon :color="wsACCENT">mdi-delete-outline</v-icon>
                    </v-btn>
                  </ws-tooltip>

                </v-sheet>
              </div>

            </div>

            <favicon-viewer dark />

          </v-sheet>

        </div>

      </template>


    </ws-accordion>

    <template #dialog>
      <avalon-language-selector
          v-model="displayLangsDialog"
          v-if="displayLangsDialog"
      >

      </avalon-language-selector>

    </template>

  </dash-page-new>
</template>

<script>
import wsBusinessServiceKeySelect
  from "@/components/pages/businessDashboard/businessSettings/UI/wsBusinessServiceKeySelect";
import {mapActions} from "vuex";
import faviconViewer from "@/components/AvalonEditor/settings/UI/faviconViewer";
import avalonLanguageSelector from "@/components/AvalonEditor/settings/UI/avalonLanguageSelector";
export default {
  name: "settingsSite",
  components : {
    wsBusinessServiceKeySelect,
    faviconViewer,
    avalonLanguageSelector
  },
  data() {
    return {
      serviceKeys : {},
      displayLangsDialog : false,
    }
  },
  computed : {
    navigationItems() {
      return [
        { name : this.$t('SitePublicationStatus') ,
          subtitle : this.$t('SitePublicationStatusDescription'),
          value : 'isPublished' ,
          icon : 'mdi-eye-outline',
          noExpand : true,
        },
        { name : 'Google Analytics' ,
          subtitle : this.$store.state.business.selectedBusiness.gTag
              ? this.$t('LinkedKey' , {value : this.$store.state.business.selectedBusiness.gTag})
              : this.$t('GoogleAnalyticsDescription')
          ,
          value : 'gAnalytics' ,
          icon : 'mdi-google',
          noExpand : true,
        },
        { name : 'Facebook Pixel: Tag Id' ,
          subtitle : this.$store.state.business.selectedBusiness.fPixelId
              ? this.$t('LinkedKey' , {value : this.$store.state.business.selectedBusiness.fPixelId})
              : this.$t('FacebookPixelDescription')
          ,
          value : 'facebook_code' ,
          icon : 'mdi-facebook',
          noExpand : true,
        },
        { name : this.$t('avalon.language.title'),
          subtitle : `${this.$t('avalon.language.primary')}: ${this.AVALON_LANG_PRIMARY.toUpperCase()}`,
          value : 'language' ,
          icon : 'mdi-translate-variant',
          noExpand : true,
        },
        { name : 'Favicon',
          subtitle : this.$t('avalon.settings.favicon.subtitle'),
          value : 'favicon' ,
          icon : 'mdi-web',
          noExpand : true,
          expanded : false
        }
      ]
    }
  },
  methods : {
    ...mapActions('avalon' , [
      'EDIT_PUBLISH_STATUS',
      'SAVE_SITE_SETTINGS'
    ]),

    async uploadFavicon(file , isDark = false) {
      let data = {}
      data[isDark ? 'favicon_dark' : 'favicon'] = file ?  file.url : null
      let result = await this.SAVE_SITE_SETTINGS(data)
      if ( !result ) {
        return this.ERROR()
      }
      this.$store.state.avalon[isDark ? 'faviconDark' : 'favicon'] = file ?  file.url : null

    },
    openLangsDialog() {
      this.displayLangsDialog = true
    },
    async editSitePublishStatus($event) {
      let data = {
        is_published: $event
      }
      let result = await this.EDIT_PUBLISH_STATUS(data)
      if (!result) {
        return
      }
      if ($event) {
        this.notify(this.$t('avalon.website.site_is_published') , 'success')
      } else {
        this.notify(this.$t('avalon.website.site_disabled'))
      }
      this.$store.state.avalon.siteIsPublished = $event
    },

    initPage() {
      this.serviceKeys.gTag = !!this.$store.state.business.selectedBusiness.gTag
      this.serviceKeys.fPixelId = !!this.$store.state.business.selectedBusiness.fPixelId
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>